<template lang="pug">
.content
  layoutContainer
    .add-product-container
      .header {{ title }}
      .body
        .product-config
          .config-name 产品名称
          .product-config-detail
            el-input(v-model="productName" maxlength="20").input-product-name
        .product-config
          .config-name 所属公司全称
          .product-config-detail
            el-input(v-model="companyName" @focus="checkCompanyName = false" maxlength="30").input-company-name
            .company-name-check(v-show="checkCompanyName") 请输入公司全称
        .product-config
          .config-name 所属分类
          .product-config-detail
            el-select(v-model="categoryOne" :class="{'select-level':isShowLevelThree}" @change="categoryOneChange")
              el-option(v-for="item in  categoryOneList" :key="item.id" :label="item.name" :value="item.id")
            el-select(v-model="categoryTwo" :class="{'select-level':isShowLevelThree}" @change="categoryTwoChange")
              el-option(v-for="item in  categoryTwoList" :key="item.id" :label="item.name" :value="item.id")
            el-select(v-model="categoryThree" v-show="isShowLevelThree")
              el-option(v-for="item in  categoryThreeList" :key="item.id" :label="item.name" :value="item.id")
        .product-config
          .config-name 一句话简介
          .product-config-detail
              el-input(type="textarea" resize="none" v-model="describing" maxlength="500")
        .product-config 
          .config-name
          .product-config-detail 
            .submit(@click="submit") {{ submitButton }}
</template>

<script>
import {getProductDetailApi, saveProductApi, getCompanyInfoByAdminApi, checkAuth} from '@/api/product'
import {getBusinessListApi} from '@/api/index.js'
import {getCompanyDetailApi} from '@/api/company.js'

export default {
  name: 'ProductDetail',
  components: {},
  data() {
    return {
      isManager: false, //是否是管理员
      isOperator: false, // 是否是运营人员
      title: '',
      submitButton: '',
      checkCompanyName: false,
      isShowLevelThree: false, // 是否展示三级分类
      productName: '', // 产品名称
      companyName: '', // 公司全称
      categoryOne: '', // 一级分类
      categoryTwo: '', // 二级分类
      categoryThree: '', // 三级分类
      categoryOneList: [], // 一级分类列表
      categoryTwoList: [
        {
          id: -1,
          name: '下级分类（可选）'
        }
      ], // 二级分类列表
      categoryThreeList: [
        {
          id: -1,
          name: '下级分类（可选）'
        }
      ], // 三级分类列表
      businessList: [],
      productTypeList: [], // 产品分类列表
      describing: ''
    }
  },
  created() {
    const {productEdit, productId, companyId} = this.$route.query
    this.title = productEdit === 'true' ? '编辑产品' : '新增产品'
    this.submitButton = productEdit === 'true' ? '修改' : '提交'

    // 产品分类列表
    getBusinessListApi({type: '产品'}).then(res => {
      this.productTypeList = res.data.productList
      this.categoryOneList = res.data.productList
      if (productEdit === 'false') {
        this.categoryOne = res.data.productList[0].id
        this.categoryOneChange(this.categoryOne)
      }
    })

    // 判断是否是编辑产品，获取产品详情
    productEdit === 'true' &&
      getProductDetailApi({id: productId}).then(res => {
        const {productName, companyName, businessList, introduction} = res.data
        this.productName = productName
        this.companyName = companyName
        this.businessList = businessList
        this.describing = introduction

        if (this.productTypeList) {
          // 一级分类
          const categoryOneResult = this.productTypeList.find(item => item.id === businessList[0].id)
          this.categoryOne = categoryOneResult.id
          // 二级分类
          this.categoryTwoList.push(...(categoryOneResult.childrenList || []))
          if (businessList[1]) {
            const categoryTwoResult = this.categoryTwoList.find(item => item.id === businessList[1].id)
            this.categoryTwo = categoryTwoResult.id
            this.categoryThreeList.push(...(categoryTwoResult.childrenList || []))
          } else {
            this.categoryTwo = -1
            const categoryTwoResult = this.categoryTwoList.find(item => item.id === businessList[1].id)
            this.categoryThreeList.push(...(categoryTwoResult.childrenList || []))
          }

          //三级分类
          if (businessList[2]) {
            this.isShowLevelThree = true
            const categoryThreeResult = this.categoryThreeList.find(item => item.id === businessList[2].id)
            this.categoryThree = categoryThreeResult.id
          } else {
            this.categoryThree = -1
          }
        }
      })

    // 判断是否有公司ID
    companyId &&
      getCompanyDetailApi({companyId}).then(res => {
        this.companyName = res.data.companyVo.name
      })

    // 判断是不是管理员
    getCompanyInfoByAdminApi().then(res => {
      if (res.data === null) return
      this.companyName = res.data.name
      this.isManager = true
    })
    if (this.UserInfo && this.UserInfo.id) {
      // 是否是运营
      checkAuth({and: true, authList: [1]}).then(res => {
        this.isOperator = res.code === 200
      })
    } else {
      this.isOperator = false
    }
  },
  methods: {
    // 一级分类
    categoryOneChange(value) {
      const childrenList = this.categoryOneList.find(item => item.id === value).childrenList
      this.categoryTwoList = [
        {
          id: -1,
          name: '下级分类（可选）'
        }
      ]
      this.categoryTwoList.push(...(childrenList || []))
      this.categoryTwo = -1
      this.categoryThree = -1
      this.isShowLevelThree = false
    },
    // 二级分类
    categoryTwoChange(value) {
      this.categoryThree = -1
      if (value === -1) {
        this.isShowLevelThree = false
        return
      }
      const childrenList = this.categoryTwoList.find(item => item.id === value).childrenList || []
      if (!childrenList.length) return
      this.categoryThreeList = [
        {
          id: -1,
          name: '下级分类（可选）'
        }
      ]
      this.categoryThreeList.push(...childrenList)
      this.isShowLevelThree = true
    },
    submit() {
      if (!this.isManager && !this.isOperator) return
      if (!this.productName) {
        this.$dialog.toast('请输入产品名称')
        return
      }
      if (this.companyName === '') {
        this.checkCompanyName = true
        return
      }

      const params = {
        name: this.productName,
        id: this.$route.query.productId || '',
        companyName: this.companyName,
        businessId: '',
        introduction: this.describing
      }
      if (this.categoryThree === -1 || !this.categoryThree) {
        if (this.categoryTwo === -1 || !this.categoryTwo) {
          params.businessId = this.categoryOne
        } else {
          params.businessId = this.categoryTwo
        }
      } else {
        params.businessId = this.categoryThree
      }
      saveProductApi(params).then(res => {
        this.$dialog.toast('保存成功')
        if (res.code === 200) {
          if (this.$route.query.companyId) {
            this.$router.push({
              path: `/companyDetail/${this.$route.query.companyId}`
            })
          } else {
            this.$router.push({
              path: '/product'
            })
          }
        } else if (res.code === 100003) {
          this.$dialog.toast('所属公司不存在，请先入驻')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-product-container {
  min-height: 1050px;
  width: 100%;
  background-color: #fff;
  padding-top: 40px;
  .header {
    padding-left: 63px;
    position: relative;
    height: 24px;
    line-height: 28px;
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 60px;
    &::after {
      content: '';
      position: absolute;
      height: 24px;
      width: 3px;
      background-color: #5ad3cd;
      left: 50px;
    }
  }
  .body {
    padding: 0 200px 0 164px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid #f1f1f1;
    }
    .product-config {
      min-height: 47px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 50px;

      .config-name {
        width: 108px;
        margin-right: 30px;
        text-align: right;
      }
      .product-config-detail {
        font-size: 18px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        .input-product-name {
          width: 100%;
        }
        .input-company-name {
          width: 320px;
        }
        .company-name-check {
          margin-left: 50px;
          color: #f30f03;
          font-size: 18px;
          font-weight: 400;
        }
        .el-select {
          margin-right: 58px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
        .select-level {
          margin-bottom: 50px;
        }
        /deep/.el-input__inner {
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 2px solid #f1f1f1;
          width: 320px;
        }
        /deep/.el-textarea__inner {
          height: 200px;
        }
      }
    }
    .submit {
      width: 370px;
      height: 46px;
      background: #5ad3cd;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 46px;
      cursor: pointer;
    }
  }
}
</style>
